
import { defineComponent, onBeforeMount } from "vue";

import { IonRouterOutlet, IonPage } from "@ionic/vue";

import CreateService from "../../../lib/service/CreateService";

export default defineComponent({
  name: "Setup",
  components: {
    IonRouterOutlet,
    IonPage,
  },
  setup() {
    onBeforeMount(() => {
      CreateService.configureNext();
    });
  },
  watch:{
    $route (to){
        if(to.path === '/create/setup') {
          CreateService.configureNext();
        }
    }
  },
});
